import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./OTPVerification.css";
import swal from "sweetalert";

const {
  BACK_IMAGE,
  CROSS_ICON,
  BACK_BUTTON,
  LOGO,
} = require("../../utils/imagepath");

const OTPVerificationSignup = (props) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const {
    expectedOtp,
    email,
    phone,
    callBackFn,
    callBaclSendOTP,
    calltoBackScreen,
    OTPType,
  } = props;
  const [isClicked, setIsClicked] = useState(false);
  const [timer, setTimer] = useState(30); // 20 seconds timer
  const [canResend, setCanResend] = useState(false);

  console.log("OPT Received --- ", expectedOtp);
  console.log("email on child component---", email);
  console.log("call back function", callBackFn);
  console.log("phone number - ", phone);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleCalltoBackScreen = () => {
    debugger;
    calltoBackScreen();
  };
  const handleOtpChange = (index, value) => {
    // Ensure the input contains only numeric characters
    if (/^[0-9]{0,1}$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input, if available
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleVerifyOtp = () => {
    debugger;
    const enteredOtp = otp.join("");
    if (enteredOtp === expectedOtp) {
      setIsClicked(true);
      //alert('OTP is correct. Verification successful!');
      callBackFn(email);
      // You can navigate to the next screen or perform additional actions here
    } else {
      swal({
        title: "Invalid OTP. Please try again.",
        icon: "error",
      });
      // You can clear the input or handle the error in any way you prefer
      setOtp(["", "", "", "", "", ""]);
      // Move focus back to the first input
      inputRefs[0].current.focus();
    }
  };
  const handleResendOTP = () => {
    if (OTPType === "f") {
      callBaclSendOTP("f");
    } else {
      callBaclSendOTP("login");
    }
  };
  return (
    <div className="flex flex-row bg-white-500">
      <div className="flex-1">
        <img
          style={{ height: "100%", width: "100%" }}
          src={BACK_IMAGE}
          alt=""
        />
      </div>
      <div className="flex-1">
        {/* {OTPType === "f" ? (
          <button
            style={{ position: "absolute", top: 16, right: 16 }}
            onClick={handleCalltoBackScreen}
          >
            X
          </button>
        ) : (
          <button onClick={handleCalltoBackScreen}>
            <img
              style={{ marginLeft: 40 }}
              src={BACK_BUTTON}
              alt="Cross Icon"
            />
          </button>
        )} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5%",
            justifyContent: "flex-end"
          }}
        >
          <Link to="/">
            <img
              style={{ marginRight: 40 }}
              src={CROSS_ICON}
              alt="Cross Icon"
            />
          </Link>
        </div>
        <div className="home-right-section">
          <img src={LOGO} width={200} />
        </div>
        <div className="otp-verification-code">
          <h2>OTP Verification</h2>
          {phone ? (
            <p>
              Please enter the 6-digit verification code that was sent to +91{" "}
              {phone.slice(0, 2)}XXXXXX{phone.slice(-2)}
            </p>
          ) : (
            <p>Please enter the 6-digit verification code</p>
          )}
          <div style={{ marginTop: "3.5rem" }}>
            <label>Enter verification code</label>
            <div className="otp-field">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={inputRefs[index]}
                  type="text"
                  maxLength="1"
                  style={{ textAlign: "center" }}
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                />
              ))}
            </div>
          </div>
          <div>
            {timer > 0 ? (
              <p
                style={{
                  margin: "10px auto 24px auto",
                  fontSize: "15px",
                }}
              >
                Didn't get the code? Resend in <span>{timer}</span> seconds
              </p>
            ) : (
              <button
                style={{
                  width: 148,
                  backgroundColor: "#00a990",
                  color: "#fff",
                  fontWeight: "bold",
                  height: 40,
                  borderRadius: 10,
                }}
                onClick={() => {
                  setTimer(20);
                  setCanResend(false);
                  setIsClicked(false);
                  handleResendOTP();
                }}
              >
                Resend OTP
              </button>
            )}
            <button
              style={{
                width: 148,
                backgroundColor: "#00a990",
                color: "#fff",
                fontWeight: "bold",
                height: 40,
                borderRadius: 10,
                marginLeft: 16
              }}
              onClick={handleVerifyOtp}
            >
              {isClicked ? "Please wait..." : "Verify OTP"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerificationSignup;
