import axios from "axios";
import { storage } from "../../utils/storage";

const TOKEN_ENDPOINT = process.env.REACT_APP_API_TOKEN_ENDDPOINT; //"https://efficiency-agility-7802--bisleriqa.sandbox.my.salesforce.com/services/oauth2/token";
const BASE_URL = process.env.REACT_APP_API_BASE_URL; //"https://efficiency-agility-7802--bisleriqa.sandbox.my.salesforce.com/";

//const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
//const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
//const USERNAME = process.env.REACT_APP_SF_UN;
//const PASSWORD = process.env.REACT_APP_SF_PASS;
const APP_TYPE = process.env.REACT_APP_TYPE;
const ENVIRONMENT = process.env.REACT_APP_APP;

console.log("process.env.BASE_URL ", BASE_URL);

let tokenRetrievalAttempts = 0;
const maxTokenRetrievalAttempts = 3;

const refreshAccessToken = async () => {
  try {
    const response = await axios.post(
      TOKEN_ENDPOINT,
      { type: APP_TYPE, app: ENVIRONMENT },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("SFDC token response: ", response);
    if (response && response.data && response.data.access_token) {
      console.log("setting acces token");
      console.log("--- response.data.access_token", response.data.access_token);
      storage.setItem("access_token", response.data.access_token);
      return response.data.access_token; // Resolve the promise with the new token
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);

    // Increment the attempts counter
    tokenRetrievalAttempts++;

    // Check if the maximum number of attempts is reached
    if (tokenRetrievalAttempts < maxTokenRetrievalAttempts) {
      // Wait for a delay and then retry
      await new Promise((resolve) => setTimeout(resolve, 500));
      return refreshAccessToken(); // Recursively call the function to retry
    } else {
      console.error("Maximum token retrieval attempts reached");
      throw new Error("Failed to refresh access token after maximum attempts");
    }
  }
};

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  async (config) => {
    debugger;
    console.log("--- request interceptor", config);
    const token = storage.getItem("access_token");
    // const token =
    //   "00D0T0000000Svl!ARsAQBHvw7Kkw7_EBrIZNtSga7Jn3N8dzfp.BmwqniI2B_i4D4PRJkGZfz8hpWx5AZYor5tGQdDDUgENfWdEI80PluSxVgyT";
    const accessToken = token ? token : "";
    console.log("--- token value from storage", accessToken);
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 unauthorized responses
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    debugger;
    console.log("response interceptor ---", error);

    if (
      error.response === undefined ||
      (error.response && error.response.status === 401)
    ) {
      console.log("--- inside request interceptors");
      // If the response status is 401, attempt to refresh the access token
      try {
        storage.removeItem("access_token");
        await refreshAccessToken();
        // Retry the original request after obtaining a new access token
        console.log("--- after new token update");
        const originalRequestConfig = error.config;
        // Modify the headers with the new token
        const accessToken = storage.getItem("access_token");
        console.log("new token ---", accessToken);
        originalRequestConfig.headers["Authorization"] =
          "Bearer " + accessToken;

        console.log("Original Request Config:", originalRequestConfig);

        return api(originalRequestConfig);
      } catch (refreshError) {
        // Handle the error from the refresh attempt
        console.error("Error refreshing access token:", refreshError);
        // Redirect to the login screen or handle the error as needed
        throw refreshError;
      }
    }
    // For other errors, just pass them through
    throw error;
  }
);

export const generateOTP = (mobile) => {
  try {
    return api.post(`/services/apexrest/requestor_register/`);
  } catch (error) {
    console.log("OTP Generation error->", error);
  }
};

/* export const createRequestor = (requestor) => {
     console.log("requesor --- ",requestor);
     try {
        return api.post(`/services/apexrest/requestor_register/`, requestor);
        
     } catch (e) {
        console.log("Error: " + e);
     }
}; */

export const createRequestor = async (requestor) => {
  try {
    // Log only if necessary, and avoid logging sensitive details
    debugger;
    console.log("Sending requestor data to Salesforce...", requestor);

    const response = await api.post(
      `/services/apexrest/requestor_register`,
      requestor
    );
    return response.data; // Return the response data
  } catch (error) {
    console.error("Error in createRequestor:", error);
    // Return or throw an error object with relevant information
    throw error;
  }
};

export const postCase = async (newCase, sfId) => {
  console.log("newCase --- ", newCase);
  try {
    const url = `/services/apexrest/post_support_case/${sfId}`;
    const response = await api.post(url, newCase);
    console.log("Response:", response.data);
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
    throw error;
  }
};

export const getDashboardData = async (sfId, duration, startDate, endDate) => {
  console.log("API Start Date", startDate);
  console.log("API End Date", endDate);
  debugger;
  try {
    console.log("Calling getDashboard API", sfId);

    if (startDate && endDate) {
      const response = await api.get(
        `/services/apexrest/requestor_final_dashbaord/${sfId}?type=${duration}&start_date=${startDate}&end_date=${endDate}`
      );

      return response.data;
    } else {
      const response = await api.get(
        `/services/apexrest/requestor_final_dashbaord/${sfId}?type=${duration}`
      );
      return response.data;
    }
  } catch (e) {
    console.error("Get Dashboard Data API Error: ", e);
    throw e;
  }
};

export const createFeedback = async (feedback, sfId, successCallback) => {
  try {
    const response = await api.post(
      `/services/apexrest/create_feedback/${sfId}`,
      feedback
    );
    successCallback(response.data);
    return response.data;
  } catch (e) {
    console.error("Error: " + e);
  }
};

export const createRequest = async (request, sfId, successCallback) => {
  console.log("request --- ", request);
  try {
    const response = await api.post(
      `/services/apexrest/create_request/${sfId}`,
      request
    );
    successCallback(response.data);
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const createRating = async (payload, sfId, successCallback) => {
  debugger;
  console.log("payload --- ", payload);
  try {
    const response = await api.post(
      `/services/apexrest/create_rating/${sfId}`,
      payload
    );
    successCallback(response.data);
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const editProfile = async (profileData, sfId) => {
  console.log("HITTING THE PROFILE UPDATE API", profileData);
  try {
    const response = await api.put(
      `/services/apexrest/update_user/${sfId}`,
      profileData
    );
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const getRequesterNotifications = async (sfId) => {
  console.log("HITTING THE NOTIFICATION API");
  try {
    const response = await api.get(
      `/services/apexrest/requestor_notification/${sfId}`
    );
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const getHelp = async (sfId) => {
  try {
    const response = await api.get(
      `/services/apexrest/admin_help_support/${sfId}`
    );
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const getMyTickets = async (sfId, offset) => {
  try {
    const response = await api.get(
      `/services/apexrest/my_tickets/${sfId}?offset=${offset}`
    );
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const getSupportCases = async (sfId) => {
  try {
    const response = await api.get(
      `/services/apexrest/get_support_cases/${sfId}`
    );
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const getFeedback = async (sfId) => {
  console.log("HITTING THE FEEDBACK API");
  try {
    const response = await api.get(`/services/apexrest/get_feedback/${sfId}`);
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const getFaqs = async (sfId) => {
  try {
    const response = await api.get(`/services/apexrest/get_faqs/${sfId}`);
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};

export const getCollectorsSoftPlastic = async (sfId) => {
  try {
    const response = await api.get(
      `/services/apexrest/collector_softplastic/${sfId}`
    );
    return response.data;
  } catch (e) {
    console.log("Error: " + e);
  }
};
export const getRequestorDashboardViewDet = async (
  sfId,
  duration,
  filter,
  startDate,
  endDate,
  offset
) => {
  try {
    console.log("Calling getRequestorDashboardViewDet API", sfId);

    if (startDate && endDate) {
      const response = await api.get(
        `/services/apexrest/requestor_dashboard_viewdetails/${sfId}?type=${duration}&start_date=${startDate}&end_date=${endDate}&filter=${filter}&offset=${offset}`
      );

      return response.data;
    } else {
      const response = await api.get(
        `/services/apexrest/requestor_dashboard_viewdetails/${sfId}?type=${duration}&filter=${filter}&offset=${offset}`
      );
      return response.data;
    }
  } catch (e) {
    console.error("Get Dashboard View Details Data API Error: ", e);
    throw e;
  }
};
